.footer-container{
  position: relative;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .contact-box{
    width: 45.8rem;
    padding-top: 5rem;
    .information{
      display: flex;
      flex-direction: row;
      padding-bottom: 2rem;
      border-bottom: 0.1rem solid rgba(255,255,255,0.3);
      .logo{
        padding-right: 1rem;
        border-right: 0.2rem solid #fff;
      }
      .address{
        padding-left: 2rem;
        color: #fff;
        span{
          font-size: 1.5rem;
          font-weight: bold;
        }
        p{
          font-size: 1.5rem;
          line-height: 1.3;
        }
      }
    }
    .desc{
      color: #fff;
      h6{
        margin: 3rem 0rem 0rem 0rem;
        font-size: 2rem;
        font-weight: bold;
      }
      p{
        font-size: 1.5rem;
        line-height: 1.6;
      }
    }
  }

  .form-box{
    width: 45rem;
    padding-top: 3rem;
    input{
      width: 100%;
      font-size: 1.6rem;
      padding: 0.8rem;
      color: #fff;
      background: transparent;
      margin: 1rem 0rem 0.2rem 0rem;
      box-sizing: border-box;
      border-radius: 3px;
      border: 0.1rem solid #fff;
    }
    textarea{
      width: 100%;
      color: #fff;
      resize: none;
      height: 10rem;
      padding: 0.8rem;
      font-size: 1.8rem;
      background: transparent;
      margin: 1rem 0rem 1rem 0rem;
      box-sizing: border-box;
      border-radius: 3px;
      border: 0.1rem solid #fff;
      font-family: "Open Sans", sans-serif;
    }
  }
}
@media only screen and (min-width: 820px) {
  .footer-container{
    justify-content: space-between;
  }
}
@media only screen and (min-width: 320px) and (max-width: 820px) {
  .logo-mini-footer{
    width: 70% !important;
    padding-top: 1rem;
  }

  .footer-container{
    flex-direction: column;
    padding: 0 3rem;

    .contact-box{
      width: 100%;

      .information{
        .logo{
          width: 35%;
          padding-right: 0px;
        }
        .address{
          font-size: 1.5rem;
          padding-left: 1.5rem;
         
          p {
            margin-bottom: 0;
          }
        }
      }
      .desc{
        p {
          text-align: justify;
        }
      }
    }
    .form-box{
      width: 100%;
      margin: auto;
    }
  }

}