:root {
  font-size: 62.5%;
  font-family: "Open Sans", sans-serif;
  /*width: 100vw;*/
  /*max-width: 100vw;*/
  /*min-width: 100vw;*/
  /*overflow-x: hidden;*/

  box-sizing: border-box;
  padding: 0;
  margin: 0;

}

body {
  /*width: 100vw;*/
  /*max-width: 100vw;*/
  /*min-width: 100vw;*/

  /*overflow-x: hidden;*/
}

.App {

  /*text-align: center;*/
}

ul {
  list-style: none;
  padding-left: 0;
}

